.cert-con {
  position: relative;
  height: 100%;
  box-sizing: border-box;
  .bg1 {
    position: fixed;
    right: 0;
    top: 80px;
    width: 560px;
    height: 330px;
    background: no-repeat url('../../Assets/bg_cert1.png');
    background-position: 0px -80px ;
    background-size: cover;
  }

  .bg2 {
    position: fixed;
    z-index: -100;
    left: 0;
    bottom: 0px;
    width: 425px;
    height: 268px;
    background: no-repeat url('../../Assets/bg_cert.png') center/cover;
  }

  .cert-info {
    position: relative;
    z-index: 100;
    width: 600px;
    margin: 30px auto;
    font-size: 14px;
    
    font-weight: 400;
    color: rgba(22, 22, 64, 0.65);
    line-height: 20px;
    background-color: #fff;
  }
}

.section7 {
  position: relative;
  top: 80px;
  width: 100%;
  height: calc(100% - 80px);
  // min-height: calc(100% - 80px);
  // min-height: 100%;
  // box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    // height: 100%;

    box-sizing: border-box;
    background: transparent;

    // background:no-repeat url('../../Assets/bg_cert1.png')  100%+11px -330px, no-repeat url('../../Assets/bg_cert.png') -280px 100% + 140px ;
    // background-size: 699px 650px;
    .cert-title1 {
      margin: 0 auto;
      width: 532/2px;
      height: 142/2px;
      background:#fff no-repeat url('../../Assets/cert-title1.png') center/cover;
    }

    .cert-title2 {
      margin: 24px auto 32px;
      width: 500/2px;
      height: 66/2px;
      background:#fff no-repeat url('../../Assets/cert-title2.png') center/cover;
    }
  }
}
.aa {
  width: 100%;
  height: 100%;
}